.input-error {
  border-color: red !important;
}

.select-error > div {
  border-color: red !important;
}

.form-control-select {
  padding: 0 10px !important;
}

#loading-bar-spinner.spinner .spinner-icon {
  width: 20px;
  height: 20px;
  border: solid 4px transparent;
  border-top-color: #7c3e7a !important;
  border-left-color: #7c3e7a !important;
  border-radius: 50%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loading-bar-spinner.spinner {
  position: absolute;
  z-index: 19 !important;
  animation: loading-bar-spinner 400ms linear infinite;
}

.padding-0-15 {
  padding: 0 15px !important;
}

.nav-link.portal {
  color: #495057 !important;
}

.alerta-limite-cadastros {
  color: #e42828;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f7f8f9 !important;
  opacity: 1;
}

.nav-portal {
  background-color: #fff !important;
}

.justifyContentCenter {
  justify-content: center !important;
}

.content-btn-novo-item {
  position: absolute;
  top: 10px;
  right: 20px;
}

.float-left {
  float: left !important;
}

.title-horarios {
  font-size: 20px;
  font-weight: bold;
}

.card-body {
  -webkit-transition: opacity 0.5s 0s ease-in;
  -moz-transition: opacity 0.5s 0s ease-in;
  -o-transition: opacity 0.5s 0s ease-in;
  transition: opacity 0.5s 0s ease-in;
}

.tag {
  background: #eaeaea;
  color: #444;
  font-size: 0.8rem;
  line-height: 20px;
  min-height: 25px;
  min-width: 20px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  letter-spacing: 0.6px;
  padding: 1px 10px;
  border-radius: 0;
  border-radius: 0.75rem;
  color: #fff;
  width: 115px !important;
}

.NOVO {
  background-color: #5a6268;
}
.ATENDENDO {
  background-color: #138496;
}
.CANCELADO {
  background-color: #c82333;
}
.FINALIZADO {
  background-color: #218838;
}

.btn-iniciar-atend {
  background-color: #138496 !important;
  border-color: #138496 !important;
}
.btn-iniciar-atend:hover {
  background-color: #3499a9 !important;
  border-color: #3499a9 !important;
}
.btn-iniciar-atend:active {
  background-color: #3499a9 !important;
  border-color: #3499a9 !important;
}
.btn-finalizar-atend {
  background-color: #218838 !important;
  border-color: #218838 !important;
}
.btn-finalizar-atend:hover {
  background-color: #399f50 !important;
  border-color: #399f50 !important;
}
.btn-finalizar-atend:active {
  background-color: #399f50 !important;
  border-color: #399f50 !important;
}

.icon-pagamento {
  cursor: pointer;
  font-size: 20px;
  position: relative;
  top: 2px;
  color: #218838;
}

.icon-editar {
  cursor: pointer;
  font-size: 20px;
  position: relative;
  top: 2px;
  color: #333;
}

.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  padding: 6px 8px !important;
}

.overflow-modal-body {
  overflow-y: scroll !important;
  max-height: 300px !important;
  overflow-x: hidden !important;
}

.card-header-portal {
  background-color: #f7f7f7 !important;
  font-size: 18px;
}

.card-body-portal {
  min-height: 100px !important;
}

#login_container {
  width: 100%;
  float: left;
  height: 100vh;
  background: #ccc;
  overflow: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.card-container-login.card-login {
  max-width: 370px;
  padding: 40px 20px;
}

.btn-signin-sac {
  font-weight: 700;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
}

#captcha {
  width: 100%;
  float: left;
  padding: 10px 0px;
}

/*
 * Card component
 */
.card-login {
  background-color: transparent !important;
  /* just in case there no content*/
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  /* shadows and rounded borders */

  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/*
 * Form styles
 */
.profile-name-card {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 0;
  min-height: 1em;
}

.reauth-email {
  display: block;
  color: #404040;
  line-height: 2;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.form-signin #cnpj,
.form-signin #login,
.form-signin #password {
  direction: ltr;
  height: 50px;
  font-size: 16px;
}

.form-signin input[type="email"],
.form-signin input[type="password"],
.form-signin input[type="text"],
.form-signin button {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: transparent !important;
  border-radius: 50px;
  color: #fff;
  text-align: center;
}

.form-signin .form-control:focus {
  border-color: rgb(104, 145, 162);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(104, 145, 162);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgb(104, 145, 162);
}

.btn.btn-signin {
  background-color: #f94;
  /*    background-color: rgb(104, 145, 162);*/
  /* background-color: linear-gradient(rgb(104, 145, 162), rgb(12, 97, 33));*/
  padding: 0px;
  font-weight: 700;
  font-size: 14px;
  height: 36px;
  border: none;
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
  margin-top: 40px;
}

.btn.btn-signin:hover,
.btn.btn-signin:active,
.btn.btn-signin:focus {
  /*    background-color: rgb(12, 97, 33);*/
  background-color: #fd831e;
  transition: all 500ms ease-out;
  opacity: 0.8;
}

.upload-message {
  display: flex;
  color: #999;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.upload-message-error {
  display: flex;
  color: #e57878;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.upload-message-success {
  display: flex;
  color: #78e5d5;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.drop-container {
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
}

.drop-active {
  border-color: #78e5d5 !important;
}

.drop-reject {
  border-color: #e57878 !important;
}

.drop-preview {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
}

.drop-file-info {
  display: flex;
  align-items: center;
}

.drop-file-info-div{
  display: flex;
  flex-direction: column;
}
.drop-file-info-span {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.drop-file-info-button{
  border: 0;
  background: transparent;
  color: #e57878;
  margin-left: 5px;
  cursor: pointer;
}

.container-file-list{
  margin-top: 20px;
}

.container-file-lista > li{
  margin-top: 20px;
}
.container-file-lista > li{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444;
}

.content-drop{
  width: 100%;
  max-width: 300px;
  margin: 30px;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
}

.text-area-form{
  max-height: none !important;
}

.modal-atividade{
  max-width: 750px !important;
}

.form-pergunta{
  border-width: 0 !important;
  border-radius: 0 !important;
  padding:0 !important;
}

.form-pergunta:focus{
  border-width: 0 !important;
}
.form-pergunta:disabled{
  background-color: inherit !important;
}

.card-form{
  min-height: 1px !important;
}

.no-padding{
  padding: 0 !important;
}

.item-switch {
  float: left;
}

.modal-footer-pergunta{
  justify-content: start !important;
}

.card-header-pergunta{
  padding: 15px !important
}

.no-margin{
  margin: 0 !important;
}

@media screen and (max-width: 769px) {  
  .content-btn-novo-item {
    position: static;
  }  
}


@media (min-width: 576px){
  .modal-consulta {
    max-width: 800px !important;
  }
}

/* Estilo para a barra de rolagem */
::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
  height: 8px; /* Altura da barra de rolagem horizontal */
}

/* Estilo para o "track" da barra de rolagem */
::-webkit-scrollbar-track {
  background: transparent; /* Cor do fundo da barra de rolagem */
  border-radius: 10px; /* Arredondamento do track */
}

/* Estilo para o "thumb" (parte que se move) da barra de rolagem */
::-webkit-scrollbar-thumb {
  background: #888; /* Cor da barra de rolagem */
  background: #555; /* Cor da barra de rolagem */
  border-radius: 10px; /* Arredondamento da barra de rolagem */
}

/* Estilo para o "thumb" ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
  background: #5e5d5d; /* Cor da barra de rolagem ao passar o mouse */
}

/* Estilo da barra de rolagem para Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin; /* Torna a barra de rolagem mais fina */
    scrollbar-color: #555 #f1f1f1; /* Cor do thumb e do track */
  }
}